import { NavLink } from "react-router-dom";

export default function NavBar() {
  return (
    <nav id="nav" className="nav">
      <NavLink
        className={({ isActive }) => {
          return `nav__item${isActive ? " active-page" : ""}`;
        }}
        to="/"
      >
        Home
      </NavLink>
      <NavLink
        className={({ isActive }) => {
          return `nav__item${isActive ? " active-page" : ""}`;
        }}
        to="/about"
      >
        About
      </NavLink>
      <NavLink
        className={({ isActive }) => {
          return `nav__item${isActive ? " active-page" : ""}`;
        }}
        to="/portfolio"
      >
        Portfolio
      </NavLink>
      <NavLink
        className={({ isActive }) => {
          return `nav__item${isActive ? " active-page" : ""}`;
        }}
        to="/resume"
      >
        Resume
      </NavLink>
      {/*<NavLink
        className={({ isActive }) => {
          return `nav__item${isActive ? " active-page" : ""}`;
        }}
        to="/settings"
      >
        Settings
      </NavLink>*/}
    </nav>
  );
}
