import { About, Hero, Portfolio, Resume } from ".";
import images from "./../images/index.js";

/*
   Images:
      images.BrandonBofur__CornMaze
      images.Bofur__LoveSac
      images.Brandon__Selfie
      images.Brandon__Selfie__Wide
*/

export default function Home() {
  return (
    <>
      <div id="home" className="home">
        <Hero
          image={images.BrandonBofur__CornMaze}
          //image={images.Bofur__LoveSac}
          //image={images.Brandon__Selfie}
          //image={images.Brandon__Selfie__Wide}
          color={"var(--secondary-color-dark-rgb)"}
          opacity={0.5}
        />
        <About />
        <Portfolio />
        <Resume />
      </div>
    </>
  );
}
