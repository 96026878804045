import { NavLink } from "react-router-dom";
import "./../styles/autoprefixerStyles/footer.css";

export default function Footer() {
  return (
    <footer id="footer" className="footer">
      © 2022 - Watkins.blue - <NavLink to="/privacy">Privacy</NavLink>
    </footer>
  );
}
