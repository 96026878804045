// Expects skills to be an array of alternating skill names and skill levels (from 0-10) => ["C#", 8, "Java", 3]
// It'll also take an array of all skill names followed by all skill levels => ["C#", "Java", 8, 3]
// If flagged as isSoftwareSkill, all skills passed to it will be hidden when printing the web page.
export default function ResumeSkill({ skills, isSoftwareSkill = false }) {
  if (
    !skills ||
    skills.length < 1 ||
    (skills[0].length !== 2 && skills.length % 2 !== 0)
  )
    return <></>;

  let isTupleFormat = true;
  let isPairedFormat = true;
  let output = [];

  // Determines whether skills input is formatted as list of tuples (2D array):
  // ie. [["skill1", skillLevel1], ["skill2", skillLevel2]]
  for (let i = 0; i < skills.length; i++) {
    if (
      skills[i].length !== 2 ||
      typeof skills[i][0] !== "string" ||
      (typeof skills[i][1] !== "number" &&
        typeof Number(skills[i][1]) !== "number")
    ) {
      isTupleFormat = false;
      break;
    }
  }
  if (isTupleFormat) isPairedFormat = false;

  // Determines whether skills input is formatted as ["skill1", skillLevel1, "skill2", skillLevel2]
  if (!isTupleFormat) {
    for (let i = 0; i < skills.length; i += 2) {
      if (
        typeof skills[i] !== "string" ||
        (typeof skills[i + 1] !== "number" &&
          typeof Number(skills[i + 1]) !== "number")
      ) {
        isPairedFormat = false;
        break;
      }
    }
  }

  // Determines whether skills input is formatted as ["skill1", "skill2", skillLevel1, skillLevel2]
  if (!isTupleFormat && !isPairedFormat) {
    for (let i = 0; i < skills.length / 2; i++) {
      if (
        typeof skills[i] !== "string" ||
        (typeof skills[i + skills.length / 2] !== "number" &&
          typeof Number(skills[i + skills.length / 2]) !== "number")
      )
        return "";
    }
  }

  const createKeyframes = (skillLevel) => {
    if (!skillLevel) return "";

    const width = Math.min(skillLevel, 100);
    return (
      <style>{`
        @keyframes progress-bar-${skillLevel} {
          0% {
            left: -${width + 1}%;
            animation-timing-function: ease-out;
          }
          ${skillLevel}% {
            left: 0;
            animation-timing-function: ease-in;
          }
          100% {
            left: 0;
            animation-timing-function: linear;
          }
        }
      `}</style>
    );
  };

  const createSkillText = (skillName, skillLevel) => {
    if (
      !skillName ||
      !skillLevel ||
      typeof Number(skillLevel) !== "number" ||
      Number(skillLevel) < 0 ||
      Number(skillLevel) > 100
    )
      return "";

    let skillTextDescription = "";
    let skillTextValue = "";

    let skillNumber =
      typeof skillLevel === "number" ? skillLevel : Number(skillLevel);
    if (skillNumber <= 10) {
      skillTextDescription =
        skillNumber >= 9
          ? "Expert"
          : skillNumber >= 7
          ? "Above Average"
          : skillNumber >= 5
          ? "Average"
          : skillNumber >= 3
          ? "Emerging"
          : "Novice";
      skillTextValue = skillNumber.toString() + "/10";
      skillNumber *= 10;
    }
    // Otherwise, assuming the use of percentage-based skill level
    else {
      skillTextDescription =
        skillNumber >= 80
          ? "Expert"
          : skillNumber >= 60
          ? "Above Average"
          : skillNumber >= 40
          ? "Average"
          : skillNumber >= 20
          ? "Emerging"
          : "Novice";

      skillTextValue = skillNumber.toString();
      skillTextValue +=
        (skillTextValue.length > 0
          ? skillTextValue[1] === "1"
            ? "st"
            : skillTextValue[1] === "2"
            ? "nd"
            : skillTextValue[1] === "3"
            ? "rd"
            : "th"
          : "") + " %"; //Percentile";//too long
    }

    return (
      <span
        key={`resume__skills--skill-${skillName}-${skillNumber.toString()}--container-span`}
        className={`skill-level__${skillNumber.toString()} skill-level__text`}
      >
        <span
          key={`resume__skills--skill-${skillName}-${skillNumber.toString()}--description-span`}
          className={`skill-level__${skillNumber.toString()} skill-level__text--description`}
        >
          {skillTextDescription}
        </span>
        <span
          key={`resume__skills--skill-${skillName}-${skillNumber.toString()}--value-span`}
          className={`skill-level__${skillNumber.toString()} skill-level__text--value`}
        >
          {skillTextValue}
        </span>
      </span>
    );
  };

  output.push(
    <span
      key="resume__skills--disclaimer"
      className="resume__skills--disclaimer"
    >
      Percentiles according to{" "}
      <a href="https://app.pluralsight.com/profile/brandon-watkins-b7">
        Pluralsight Skill IQ
      </a>
    </span>
  );

  for (
    let i = 0, j = isTupleFormat ? 0 : isPairedFormat ? 1 : skills.length / 2;
    j < skills.length;
    i += isPairedFormat ? 2 : 1, j += isPairedFormat ? 2 : 1
  ) {
    const skillName = isTupleFormat ? skills[i][0] : skills[i];
    const skillLevel = isTupleFormat ? skills[i][1] : skills[j];
    const skillLevelPercentage =
      skillLevel <= 10 ? skillLevel * 10 : skillLevel;

    output.push(
      <div
        key={`resume__skills--skill-${skillName}--div`}
        className={`resume__skills--skill${
          skillLevel > 10 ? " skill__pluralsight-skill-iq" : ""
        }${isSoftwareSkill ? " software" : ""}`}
      >
        <h4 key={`resume__skills--skill--${skillName}--h4`}>{skillName}</h4>
        <div
          key={`resume__skills--skill--${skillName}--progress-bar--container`}
          className="progress-bar__container"
        >
          <span
            key={`resume__skills--skill--${skillName}--progress-bar`}
            className={`progress-bar skill-level__${skillLevelPercentage}`}
            style={{
              animation: `progress-bar-${skillLevelPercentage} 5s infinite alternate`,
              width: `${skillLevelPercentage}%`,
            }}
          >
            {createKeyframes(skillLevelPercentage)}
          </span>
          {createSkillText(skillName, skillLevel)}
        </div>
      </div>
    );
  }

  return output;
}
