import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";

const HeadMeta = (page = "", style = null) => {
  const pageURL = useLocation();
  const pageRef = useRef("");
  updatePageRef();

  const defaultTitle = "Brandon.";
  const title = (
    <title>{`${
      pageRef.current && pageRef.current.length > 0
        ? pageRef.current !== "/"
          ? pageRef.current + " | "
          : ""
        : ""
    } ${defaultTitle}`}</title>
  );

  /*const css =
    pageRef.current && pageRef.current.length > 0 ? (
      <link
        rel="stylesheet"
        type="text/css"
        href={`./styles/${pageRef.current
          .toString()
          .toLowerCase()
          .replaceAll(" ", "-")}.css`}
      />
    ) : (
      ""
    );*/

  const description = (
    <meta
      name="description"
      content={`${
        pageRef.current === "" || pageRef.current === "/"
          ? "Home"
          : pageRef.current
      } page in Brandon's React Web App`}
    />
  );

  function updatePageRef() {
    let page = pageURL.pathname.trim();
    page = page
      .substring(
        page.charAt(0) === "/" ? 1 : 0,
        page.length - (page.charAt(page.length - 1) === "/" ? 1 : 0)
      )
      .trim();
    return (pageRef.current =
      page && page.length > 0
        ? page.substring(0, 1).toUpperCase() +
          (page.length > 1 ? page.substring(1) : "")
        : "");
  }

  useEffect(() => {
    updatePageRef();
  }, [pageURL]);

  return (
    <Helmet>
      {title}
      {description}
      {/*<link rel="stylesheet" type="text/css" href="./styles/style.css" />*/}
      {/*css*/}
    </Helmet>
  );
};

export default HeadMeta;
