import { ResumeCourse } from "./";

export default function ResumeCourseList() {
  return (
    <>
      <ResumeCourse
        id="INFO 1150"
        title="Software and Systems Architecture"
        technologies=""
        description=""
        projects=""
      />

      <ResumeCourse
        id="CS 1181"
        title="Computer Science and Programming I"
        technologies="C#"
        description=""
        projects=""
      />

      <ResumeCourse
        id="CS 1187"
        title="Applied Discrete Structures"
        technologies=""
        description=""
        projects=""
      />

      <ResumeCourse
        id="CS 1337"
        title="Systems Programming and Assembly"
        technologies="ARM-v7 Assembly, Linux"
        description=""
        projects=""
      />

      <ResumeCourse
        id="INFO 2220"
        title="Client-Side Web Development"
        technologies="HTML, CSS, JavaScript, jQuery"
        description=""
        projects=""
      />

      <ResumeCourse
        id="CS 2235"
        title="Data Structures and Algorithms"
        technologies="Java"
        description=""
        projects=""
      />

      <ResumeCourse
        id="CS 2263"
        title="Advanced Object-Oriented Programming"
        technologies="Java"
        description=""
        projects=""
      />

      <ResumeCourse
        id="CS 3305"
        title="Introduction to Computational Theory"
        technologies=""
        description=""
        projects=""
      />

      <ResumeCourse
        id="CS 3321"
        title="Systems Analysis and Design"
        technologies="C#"
        description=""
        projects=""
      />

      <ResumeCourse
        id="INFO 3337"
        title="Advanced Systems Programming"
        technologies="ARM-v7 Assembly, Linux"
        description=""
        projects=""
      />

      <ResumeCourse
        id="INFO 3380"
        title="Networking and Virtualization"
        technologies="Linux"
        description=""
        projects=""
      />

      <ResumeCourse
        id="INFO 4407"
        title="Database Design and Implementation"
        technologies="MySQL, MSSQL, MariaDB"
        description=""
        projects=""
      />

      <ResumeCourse
        id="INFO 4411"
        title="Intermediate Information Assurance"
        technologies="Linux"
        description=""
        projects=""
      />

      <ResumeCourse
        id="CS 4412"
        title="Advanced Algorithms"
        technologies="Python"
        description=""
        projects=""
      />

      <ResumeCourse
        id="INFO 4430"
        title="Web Application Development"
        technologies="HTML, CSS, JavaScript, PHP, ASP.NET Core, MySQL"
        description=""
        projects=""
      />

      <ResumeCourse
        id="CS 4488"
        title="Capstone Project"
        technologies="C#"
        description=""
        projects=""
      />

      <ResumeCourse
        id="CS 4499"
        title="Applied Neural Networks"
        technologies="Python"
        description=""
        projects=""
      />

      <ResumeCourse
        id="CS 4499"
        title="Software Entrepreneurship"
        technologies=""
        description=""
        projects=""
      />
    </>
  );
}
