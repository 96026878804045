export default function PageNotFound() {
  return (
    <div id="page-not-found" className="page-not-found page">
      <header className="page__title">
        <h1>Page Not Found</h1>
      </header>
      <div className="page__body">
        <p>There's nothing here!</p>
      </div>
    </div>
  );
}
