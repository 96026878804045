/* title can include links and other objects.
technologies, description, and bulletPoints all expect either a string or a list of strings.
 */
import { useState } from "react";

export default function ResumeCard({
  resumeSection,
  title,
  itemClassName = "",
  technologies,
  description,
  bulletPoints,
  subTitle,
  expandedDescription,
  expandedElementListHeading,
  expandedElementList,
  expandedBulletPointHeading,
  expandedBulletPoints,
}) {
  const [isCollapsed, setCollapsed] = useState(true);

  if (
    !resumeSection ||
    !title ||
    (!description && itemClassName.toLowerCase() !== "course")
  )
    return "";

  const canExpand =
    subTitle ||
    expandedDescription ||
    expandedElementListHeading ||
    expandedElementList ||
    expandedBulletPointHeading ||
    expandedBulletPoints;

  const toggle = (e) => {
    if (canExpand) setCollapsed(!isCollapsed);
    e.stopPropagation();
  };

  let section = `resume__${resumeSection.toLowerCase().trim()}`;
  let titleString = itemClassName && itemClassName !== "" ? itemClassName : "";
  let prefix = "";

  try {
    titleString = titleString.toLowerCase();
  } catch (e) {
    titleString = "";
  }

  const convertClassString = (str) => {
    return str
      .toString()
      .trim()
      .replaceAll(" ", "-")
      .replaceAll(",", "")
      .replaceAll(".", "")
      .replaceAll("#", "")
      .replaceAll("$", "")
      .replaceAll("/", "")
      .replaceAll("'", "")
      .replaceAll('"', "")
      .replaceAll("`", "")
      .replaceAll("(", "")
      .replaceAll(")", "");
  };

  titleString = convertClassString(titleString);

  prefix = `${section}${titleString !== "" ? "--" : ""}${titleString}`;
  title =
    typeof title === "object" ? (
      <div className={`${prefix}--title card__title`}>{title}</div>
    ) : (
      <h4 className={`${prefix}--title card__title`}>{title}</h4>
    );
  subTitle = subTitle ? (
    resumeSection.toLowerCase() !== "education" ||
    itemClassName.toLowerCase() !== "course" ? (
      <h5 className={`${prefix} card__expanded--title`}>{subTitle}</h5>
    ) : (
      <p className={`${prefix} card__expanded--description`}>{subTitle}</p>
    )
  ) : (
    ""
  );
  expandedElementListHeading = expandedElementListHeading ? (
    subTitle ? (
      <h6 className={`${prefix} card__expanded--element-list-heading`}>
        {expandedElementListHeading}
      </h6>
    ) : (
      <h5 className={`${prefix} card__expanded--element-list-heading`}>
        {expandedElementListHeading}
      </h5>
    )
  ) : (
    ""
  );

  if (technologies) {
    if (Array.isArray(technologies)) {
      if (technologies.length === 1 && Array.isArray(technologies[0]))
        technologies = technologies[0];

      let temp = "";
      technologies.forEach((e) => {
        if (typeof e === "string") {
          if (temp.length > 0) temp += ", " + e;
          else temp = e;
        }
      });
      technologies = temp;
    } else if (typeof technologies !== "string") {
      technologies = "";
    }
  } else technologies = "";
  technologies = technologies ? (
    <span className={`${prefix}--technologies card__technologies`}>
      {technologies}
    </span>
  ) : (
    ""
  );

  const makeDescription = (key, descriptionList) => {
    if (!key || !descriptionList) return "";

    if (
      Array.isArray(descriptionList) &&
      descriptionList.length === 1 &&
      Array.isArray(descriptionList[0])
    )
      descriptionList = descriptionList[0];

    if (Array.isArray(descriptionList)) {
      let temp = [];
      descriptionList.forEach((element, index) => {
        temp.push(
          <p key={`${key} ${index}`} className={key}>
            {element}
          </p>
        );
      });
      return temp.length > 0 ? temp : "";
    } else if (typeof descriptionList === "string") {
      /*// If string ends with punctuation, lower = uppercase. Return a p, instead of span.
      if (
        descriptionList[descriptionList.length - 1].toLowerCase() !==
        descriptionList[descriptionList.length - 1].toUpperCase()
      )
        return <span className={key}>{descriptionList}</span>;
      else return <p className={key}>{descriptionList}</p>;*/
      return <span className={key}>{descriptionList}</span>;
    } else return "";
  };

  const makeBulletPoints = (key, bulletPointList) => {
    if (!key || !bulletPointList) return "";

    if (
      Array.isArray(bulletPointList) &&
      bulletPointList.length === 1 &&
      Array.isArray(bulletPointList[0])
    )
      bulletPointList = bulletPointList[0];

    if (Array.isArray(bulletPointList)) {
      let temp = [];
      bulletPointList.forEach((element, index) => {
        temp.push(<li key={`${key} ${element}`}>{element}</li>);
      });
      bulletPointList = temp;
    } else if (typeof bulletPointList === "string") {
      bulletPointList = <li key={key}>{bulletPointList}</li>;
    }
    if (bulletPointList.length > 0) {
      bulletPointList = (
        <ul key={`${key}s`} className={`${key}s`}>
          {bulletPointList}
        </ul>
      );
    } else bulletPointList = "";
    return bulletPointList;
  };

  return (
    <div
      key={prefix}
      onLoad={() => {
        setCollapsed(true);
        return true;
      }}
      className={`${prefix}--card card__${
        canExpand ? "expandable" : "non-expandable"
      } card${isCollapsed ? " collapsed" : ""}`}
      onClick={toggle}
    >
      <div className="card__main-content">
        {title}
        {technologies}
        {makeDescription(`${prefix} card__description`, description)}
        {makeBulletPoints(`${prefix} card__bullet-point`, bulletPoints)}
      </div>
      {canExpand ? (
        <div
          className={`card__collapsing-content${
            isCollapsed ? " collapsed" : ""
          }`}
        >
          {subTitle}
          {makeDescription(
            `${prefix} card__expanded--description`,
            expandedDescription
          )}
          {expandedElementListHeading}
          {expandedElementList ? (
            <div className={`${prefix} card__expanded--element-list`}>
              {expandedElementList}
            </div>
          ) : (
            ""
          )}
          {expandedBulletPointHeading ? (
            <p className={`${prefix} card__expanded--bullet-point-heading`}>
              {expandedBulletPointHeading}
            </p>
          ) : (
            ""
          )}
          {makeBulletPoints(
            `${prefix} card__expanded--bullet-point`,
            expandedBulletPoints
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
