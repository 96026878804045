import { Logo, NavBar } from ".";
import "./../styles/autoprefixerStyles/header.css";

export default function Header() {
  return (
    <header id="header" className="header">
      <Logo />
      <NavBar />
    </header>
  );
}
