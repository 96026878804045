import { AboutSection } from "../components";
import { NavLink } from "react-router-dom";

export default function About() {
  const hobbies = [
    "I enjoy chilling with my dog, Bofur, playing video games with my brothers (Tiny Tina's Wonderlands), reading (Name of the Wind), and watching movies (Harry Potter). I generally spend my free time programming misc. things (my favorite project thus far has been my WoW bot, which is (was) fully functional). I'm still relatively new to the programming scene, but I'm thoroughly enjoying it and am passionate about it. While I believe the sun is evil, I also enjoy snowboarding and mountain biking.",
  ];

  const school = [
    "I recently earned a BS in Computer Science, minoring in Informatics (web development), through Idaho State University. I graduated with Cum Laude Honors distinction, with a 3.67 GPA. I was placed on the Dean's / President's list each semester, for being at the top of my class.I'm still relatively new to the programming scene, but I thoroughly enjoy it and am passionate about it. I am also a member of Tau Beta Pi, the most prestigious engineering honors society.",
  ];

  const about = [
    "Greetings, I'm Brandon Watkins! I currently live in New Mexico, but I'm looking to relocate to Washington, Oregon, Utah, or some other green, rainy, state.",

    "I have high functioning autism (Asperger's), which has certainly played a part in shaping my personality. I am quiet, focused, driven. I'm always trying to improve, to be the best version of myself. I've learned to be more \"normal\" (if there is such a thing); most people wouldn't assume I have Asperger's, just that I'm more straight-forward and have a dry sense of humor.",

    "I am an extremely hard worker, and I work and live my life with the utmost integrity. I'm a bit of a perfectionist; I don't like to submit unfinished work, or work that can be improved upon. I tend to think things out more fully before moving forward with something, making me slightly slower, but I tend to have fewer bugs in my code. I often spend substantial time outside of work hours learning to improve my code or methodologies.",

    "I have a puppy, Bofur (named after one of the dwarves from The Hobbit, due to his floppy ears), and I'm the third of four siblings, all brothers, and enjoy spending time with them when I can.",
  ];

  return (
    <div id="about" className="about page">
      <header className="page__title">
        <h1>About Me</h1>
      </header>
      <div className="page__body">
        <AboutSection title="Brandon" description={about} />
        <AboutSection title="Hobbies" description={hobbies} />
        <AboutSection title="School" description={school} />
        <p>
          For a list of skills, experience, and completed ISU courses, see my{" "}
          <NavLink to="/resume">resume</NavLink>.
        </p>
      </div>
    </div>
  );
}
