export default function ResumeSection({ header, body }) {
  let cName = header
    ? (typeof header === "object" &&
        header.props &&
        header.props.children &&
        header.props.children.toLowerCase().trim() === "brandon watkins") ||
      (typeof header === "string" &&
        header.toLowerCase().trim() === "brandon watkins")
      ? "header"
      : typeof header === "string"
      ? header
      : ""
    : "";

  const isH1 = cName === "header";

  cName = (cName ? "resume__" + cName : "")
    .toLowerCase()
    .trim()
    .replaceAll(" ", "-");

  let head = header ? (
    typeof header === "string" ? (
      isH1 ? (
        <h1>
          {header}
          {/*<NavLink to="/">{header}</NavLink>*/}
        </h1>
      ) : (
        <h3>{header}</h3>
      )
    ) : (
      ""
    )
  ) : (
    ""
  );

  return (
    <section className={cName}>
      <header className={cName + "--header"}>{head}</header>
      {
        // When I add more dates (work experience, etc.), I need to modify body to take an array of jsx components,
        // and group the dates with the rest of the data, to help with formatting.
        body ? (
          isH1 ? (
            <header className={cName + "--body"}>
              <h2>{body}</h2>
            </header>
          ) : (
            <div className={cName + "--body"}>{body}</div>
          )
        ) : (
          ""
        )
      }
    </section>
  );
}
