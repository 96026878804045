import {
  ResumeCourseList,
  ResumeCard,
  ResumeSection,
  ResumeSkill,
} from "./../components";
import { NavLink } from "react-router-dom";
import "./../styles/autoprefixerStyles/resume.css";

export default function Resume() {
  return (
    <div id="resume" className="resume page">
      <NavLink to="/" className="page__title">
        <div className="resume__block--heading">
          <ResumeSection header="Brandon Watkins" body={"Software Developer"} />
        </div>
      </NavLink>

      <div className="resume__block--bodies-of-text page__body">
        <div className="resume__block--small-bodies-of-text">
          <ResumeSection
            header="Contact"
            body={
              <address>
                <div className="resume__contact--phone">
                  <a href="tel: 3855397075">
                    <h4>
                      <svg viewBox="0 0 512 512">
                        <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                      </svg>
                      Phone
                    </h4>
                    <span>385.539.7075</span>
                  </a>
                </div>
                <div className="resume__contact--email">
                  <a href="mailto: brandon@watkins.blue">
                    <h4>
                      <svg viewBox="0 0 512 512">
                        <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                      </svg>
                      Email
                    </h4>
                    <span>Brandon@Watkins.blue</span>
                  </a>
                </div>
                <div className="resume__contact--www">
                  <a href="https://www.watkins.blue">
                    <h4>
                      <svg viewBox="0 0 512 512">
                        <path d="M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5v39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9v39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7v-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1H257c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zm48 0c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256z" />
                      </svg>
                      www
                    </h4>
                    <span>Watkins.blue</span>
                  </a>
                </div>
                <div className="resume__contact--linkedin resume__contact--social-media">
                  <a href="https://www.linkedin.com/in/brandonwatkins42">
                    <h4>
                      <svg viewBox="0 0 448 512">
                        <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                      </svg>
                      LinkedIn
                    </h4>
                    <span>linkedin.com/in/brandonwatkins42</span>
                  </a>
                </div>
                <div className="resume__contact--github resume__contact--social-media">
                  <a href="https://www.github.com/Brandon-Watkins">
                    <h4>
                      <svg viewBox="0 0 496 512">
                        <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" />
                      </svg>
                      GitHub
                    </h4>
                    <span>github.com/Brandon-Watkins</span>
                  </a>
                </div>
                <div className="resume__contact--pluralsight resume__contact--social-media">
                  <a href="https://app.pluralsight.com/profile/brandon-watkins-b7">
                    <h4>
                      <svg viewBox="0 0 32 32">
                        <path d="M0 0V32H32V0H0ZM9.4053 12.7438L15.088 16L9.4053 19.287V12.7438ZM9.4053 24.8503V21.6468L19.1842 16L9.4053 10.3532V7.17166L24.6955 16L9.4053 24.8503Z" />
                      </svg>
                      Pluralsight Skill IQs
                    </h4>
                    <span>app.pluralsight.com/profile/brandon-watkins-b7</span>
                  </a>
                </div>
              </address>
            }
          />

          <ResumeSection
            header="Skills"
            body={
              <>
                <ResumeSkill
                  skills={[
                    ["HTML/CSS", 90],
                    ["React/JavaScript", 75],
                    ["C#", 7],
                    ["Lua", 7],
                    ["Java", 6],
                    ["Python", 6],
                    ["PHP", 6],
                    ["ASP.NET Core", 6],
                    ["MySQL/MSSQL", 5],
                  ]}
                />
              </>
            }
          />
        </div>

        <div className="resume__block--large-bodies-of-text">
          <ResumeSection
            header="Profile"
            body={
              <>
                <p>
                  I recently earned my B.S. in Computer Science, minoring in
                  Informatics, with Cum Laude Honors distinction. I’m an
                  exceptionally hard worker and I live my life with integrity.
                  I’m constantly looking for opportunities to improve myself.
                </p>
                <p>
                  I have strong interests in web and mobile app development, but
                  I enjoy all aspects of programming that I've been exposed to
                  thus far.
                </p>
              </>
            }
          />

          <ResumeSection
            header="Education"
            body={
              <>
                <ResumeCard
                  resumeSection="Education"
                  itemClassName="isu"
                  title="B.S. in Computer Science, Minor in Informatics (Web Development)"
                  technologies="2017 - 2022"
                  description="Idaho State University"
                  bulletPoints={[
                    "Graduated with Cum Laude Honors distinction at a 3.67 GPA",
                    "Placed on Dean’s / President’s list each semester",
                    "Member of Tau Beta Pi, the most prestigious engineering honor society",
                  ]}
                  subTitle="Course Work"
                  expandedDescription="I've had group projects most semesters, giving me some experience working as part of a team of developers. Almost every group that I've been assigned to lacked any sort of leadership, resulting in myself stepping up as a leader. I had a significant number of opportunities to work with especially lazy students. This, both, gave me experience motivating people to work, and showcases that I'll do whatever it takes to succeed, as I still achieved good grades despite these hindrances."
                  expandedElementListHeading="Courses Taken"
                  expandedElementList={<ResumeCourseList />}
                />
              </>
            }
          />

          <ResumeSection
            header="Experience"
            body={
              <>
                <ResumeCard
                  resumeSection="Experience"
                  title="World of Warcraft Bot"
                  technologies="Lua Script"
                  description="Controls character movement, combat, and inventory management. In addition to typical bot capabilities:"
                  bulletPoints={[
                    "Able to mail or sell items when inventory is full",
                    "Avoids large groups of enemies",
                    "Safely retreats when health is low or too many enemies are present",
                    "All character movement and attacks are randomized and delayed to appear more human",
                  ]}
                />
                <ResumeCard
                  resumeSection="Experience"
                  title="Time Tracker"
                  technologies="C#"
                  description="Keeps track of the time I spend working on a given task"
                  bulletPoints={[
                    "Keeps a log of how much time I spend on each task, including dates and times",
                    "Reminds me to take a break every few hours",
                  ]}
                />
                <ResumeCard
                  resumeSection="Experience"
                  title="Task Manager Web App"
                  technologies="HTML, CSS, JavaScript, PHP, ASP.NET Core, MSSQL"
                  description="To do list on steroids: planned my day, while helping to live a balanced lifestyle"
                  bulletPoints={[
                    "Tasks could be assigned categories, priority levels, and subtasks, and labelled as an appointment or deadline",
                    "Planned your day for you, around high-priority appointments and deadlines, filling in the gaps by priority, while trying to keep the same category together",
                  ]}
                />
                <ResumeCard
                  resumeSection="Experience"
                  title="Personal Website"
                  technologies="HTML, CSS, JavaScript, React.js, JSX, Sass"
                  description="A website (watkins.blue) to host my portfolio, contact info, resume, etc."
                  bulletPoints={[
                    "Recently remade the entire website with React and Sass",
                  ]}
                />
                <ResumeCard
                  resumeSection="Experience"
                  title="School Assignments"
                  technologies="C#, Java, Python, PHP, ASP.NET Core, MySQL, MSSQL, MariaDB, HTML, CSS, JavaScript, ARM-v7 Assembly"
                  description="Various programming assignments and projects"
                  bulletPoints={[
                    "I tended to lead my group projects and oversee the designs",
                  ]}
                />
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}
