import { Footer, Header, HeadMeta } from "./components";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  Hero,
  Home,
  About,
  Portfolio,
  Resume,
  Privacy,
  PageNotFound,
} from "./pages";
import "./styles/autoprefixerStyles/style.css";
//import { fbApp, fbFirestore, fbAnalytics } from "./firebase";
// causes an error when called:
// import { firebaseConfig, fbApp, fbAnalytics, fbFirestore } "./firebase";
// Unsure if I want to keep the firebase code.
//import "./styles/firebase.css";

export default function App() {
  let title = useLocation().pathname;
  if (title && title.length > 0) {
    title = title.trim();
    if (title[0] === "/") title = title.substring(1);
    if (title.length > 1 && title[title.length - 1] === "/")
      title = title.substring(0, title.length);
    title = title.trim();
  }

  return (
    <>
      <Header />
      <HeadMeta page={title} />
      <main id="content" className="content">
        <Routes>
          <Route index exact path="" element={<Home />} />
          <Route exact path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route path="/resume" element={<Resume />} />

          <Route path="/privacy" element={<Privacy />} />
          <Route path="/hero" element={<Hero />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}
