import { Link } from "react-router-dom";

export default function PortfolioItem({
  title,
  description,
  url,
  images,
  imageAltTexts,
  videos,
}) {
  function titleTemplate() {
    return title ? (
      <header>
        <h2 className="portfolio__item--title">{title}</h2>
      </header>
    ) : (
      <></>
    );
  }

  function descriptionTemplate() {
    let descArray = [];
    Array.isArray(description)
      ? description.forEach((item) =>
          descArray.push(<p key={title + ": " + item}>{item}</p>)
        )
      : description
      ? descArray.push(<p key={title + ": " + description}>{description}</p>)
      : (descArray = []);
    return <div className="portfolio__item--description">{descArray}</div>;
  }

  function urlTemplate() {
    return url ? (
      url.toString().toLowerCase().indexOf("github.com") >= 0 ? (
        <div className="portfolio__item--url github">
          <a href={url}>{title} (Github Repo)</a>
        </div>
      ) : url.toString().indexOf(".") >= 0 &&
        url.toString().toLowerCase().indexOf("watkins.blue") < 0 ? (
        <div className="portfolio__item--url">
          <a href={url}>{title}</a>
        </div>
      ) : (
        <div className="portfolio__item--url">
          <Link to={`/${url}`}>{title}</Link>
        </div>
      )
    ) : (
      <></>
    );
  }

  function imagesTemplate() {
    let imgArray = [];
    Array.isArray(images)
      ? images.forEach((item, index) =>
          imgArray.push(
            <img
              className="portfolio__item--image"
              src={"./images/" + item}
              alt={imageAltTexts[index] ? imageAltTexts[index] : item}
              key={title + ": " + item}
            />
          )
        )
      : images
      ? imgArray.push(
          <img
            className="portfolio__item--image"
            src={"./images/" + images}
            alt={imageAltTexts ? imageAltTexts : images}
            key={title + ": " + images}
          />
        )
      : (imgArray = []);
    return <div className="portfolio__item--images">{imgArray}</div>;
  }

  function videosTemplate() {
    let vidArray = [];
    Array.isArray(videos)
      ? videos.forEach((item) =>
          vidArray.push(
            <video
              controls
              className="portfolio__item--video"
              key={title + ": " + item}
            >
              <source
                src={"./videos/" + item}
                type={"video/" + item.split(".").slice(-1)}
              />
              The video tag is unsupported by your browser.
            </video>
          )
        )
      : videos
      ? vidArray.push(
          <video
            controls
            className="portfolio__item--video"
            key={title + ": " + videos}
          >
            <source
              src={"./videos/" + videos}
              type={"video/" + videos.split(".").slice(-1)}
            />
            The video tag is unsupported by your browser.
          </video>
        )
      : (vidArray = []);
    return <div className="portfolio__item--videos">{vidArray}</div>;
  }

  return (
    <section className="portfolio__item">
      {titleTemplate()}
      {urlTemplate()}
      {descriptionTemplate()}
      {imagesTemplate()}
      {videosTemplate()}
    </section>
  );
}
