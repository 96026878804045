import { ResumeCard } from "./";

export default function ResumeCourse({
  id,
  title,
  technologies,
  description,
  projects,
}) {
  if (!id || !title) return "";

  return (
    <ResumeCard
      title={
        <>
          <span className="course__id">{id}</span>
          <span className="course__title">{title}</span>
        </>
      }
      technologies={technologies ? technologies : ""}
      subTitle={description ? "Course Description" : ""}
      expandedDescription={description ? description : ""}
      expandedBulletPointHeading={projects ? "Noteworthy Course Work" : ""}
      expandedBulletPoints={
        projects ? (Array.isArray(projects) ? projects : [projects]) : ""
      }
      resumeSection="education"
      itemClassName="course"
    />
  );
}
