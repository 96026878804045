export default function AboutSection({ title, description }) {
  let descArray = [];
  Array.isArray(description)
    ? description.forEach((item) =>
        descArray.push(<p key={title + ": " + item}>{item}</p>)
      )
    : description
    ? descArray.push(<p key={title + ": " + description}>{description}</p>)
    : (descArray = []);
  return (
    <section className="about-section">
      {title ? (
        <header>
          <h2 className="about-section__title">{title}</h2>
        </header>
      ) : (
        ""
      )}
      {descArray.length > 0 ? (
        <div className="about-section__description">{descArray}</div>
      ) : (
        ""
      )}
    </section>
  );
}
