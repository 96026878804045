import "./../styles/autoprefixerStyles/hero.css";
import images from "./../images/index.js";

export default function Hero({ image = "", color = "", opacity = "" }) {
  /*
      Images:
         images.BrandonBofur__CornMaze
         images.Bofur__LoveSac
         images.Brandon__Selfie
         images.Brandon__Selfie__Wide
   */
  console.log(image);
  image =
    image && typeof image === "string" && image !== ""
      ? images.length > 0 && images[0] !== "/"
        ? images[image]
        : image
      : images["BrandonBofur__CornMaze"];
  image = `url(${image})`;

  opacity =
    opacity &&
    (typeof opacity === "string" || typeof opacity === "number") &&
    opacity !== ""
      ? opacity
      : "0.4";

  color =
    color && typeof color === "string" && color !== ""
      ? color
      : "var(--secondary-color-rgb)";
  color =
    color.length >= 4 && color.substring(0, 4) === "rgba"
      ? color
      : "rgba(" + color + ", " + opacity + ")";
  let bgColor = color;
  color = `linear-gradient(${color}, ${color})`;

  return (
    <div
      id="hero"
      className="hero page"
      style={{ backgroundColor: `${bgColor}` }}
    >
      <div className="page__title">
        <header className="hero__text">
          <h1 className="hero__text--header">Brandon.</h1>
          <h2 className="hero__text--body">Software Developer</h2>
        </header>
      </div>
      <div
        className="page__body hero__image"
        style={{
          backgroundImage: [`${color}`, `${image}`],
        }}
      ></div>
    </div>
  );
}
