import { NavLink } from "react-router-dom";

export default function Logo() {
  return (
    <div id="logo" className="logo">
      <NavLink id="logo__link" className="logo__link" to="/">
        <div id="logo__text" className="logo">
          Brandon.
        </div>
      </NavLink>
    </div>
  );
}
