import { PortfolioItem } from "../components";

export default function Portfolio() {
  const wowBotDescription = [
    "A fully functional World of Warcraft bot. This takes user input to determine the route, whether or not to attack " +
      "low-level enemies, where to sell, and where/who to mail items to (only items that meet/exceed the user-set quality). " +
      "It will then patrol the route and attack any nearby qualifying enemies, repair, sell, and mail excess loot when " +
      "appropriate. It has a GUI for user input, but no GUI for displaying the route it's patrolling.",

    "This was coded using Lua, and depends on several other libraries. It began as a simple(ish) combat rotation bot " +
      "(that didn't move the player at all), and slowly progressed into a full-fledged bot, so the code is a bit messy, " +
      "and is not how I would write it if I were to rewrite it today.",
  ];
  const taskmasterDescription = [
    'A web app that allows you to create tasks and organize your day around them. Meant to replace a simple "To do" app, ' +
      "for those that find themselves writing a new to do list every single day, with repeating tasks, and for helping the " +
      "user to balance their lives better - allocating time for various categories that might be neglected, such as " +
      "Physical or Spiritual (say you want to begin exercising or reading the Bible for 15 minutes each day).",
  ];
  return (
    <div id="portfolio" className="portfolio page">
      <header className="page__title">
        <h1>Portfolio</h1>
      </header>
      <div className="portfolio__items page__body">
        <PortfolioItem title="WoW Bot" description={wowBotDescription} />
        <PortfolioItem
          title="Taskmaster Web App"
          description={taskmasterDescription}
        />
      </div>
    </div>
  );
}
