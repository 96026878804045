export default function Privacy() {
  return (
    <div id="privacy" className="privacy page">
      <header className="page__title">
        <h1>Privacy</h1>
      </header>
      <div className="page__body">
        <p>
          This website doesn't currently track or use cookies or any other user
          information. If this changes in the future, you will be alerted.
        </p>
      </div>
    </div>
  );
}
