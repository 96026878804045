import { default as BrandonBofur__CornMaze } from "./IMG_0047.png";
import { default as Bofur__LoveSac } from "./IMG_3357.png";
import { default as Brandon__Selfie } from "./IMG_4287.png";
import { default as Brandon__Selfie__Wide } from "./IMG_4287-Wide.png";

const images = {};
images.BrandonBofur__CornMaze = BrandonBofur__CornMaze;
images.Bofur__LoveSac = Bofur__LoveSac;
images.Brandon__Selfie = Brandon__Selfie;
images.Brandon__Selfie__Wide = Brandon__Selfie__Wide;

export default images;
